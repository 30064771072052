import React, { useState } from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import Leadership from "../page-components/company/Leadership/Leadership";
import { ACTIVE_PAGE } from "../components/nav/utils";
import { styled } from "@mui/material";

export default function CompanyPage() {
  const [selectedCategory, setSelectedCategory] = useState("Leadership");

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.COMPANY}>
      <Seo
        title="Company | Robotic Imaging"
        description="Construction Imaging, LiDAR Scanning, Drone Photography, and other imaging services. Great service and competitive prices."
      />
      <header className="site__header"></header>

      <main className="content">
        <Header>Leadership</Header>
        <HighlightedText>
          Interested in joining our team? Check out our&nbsp;
          <a href="https://www.roboticimaging.com/careers">
            <strong>open roles</strong>
          </a>
        </HighlightedText>
        <InfoText>
          Robotic Imaging collects and analyzes assets with BIM, 3D scanning, VR
          imaging and Drones. Our digital strategy is tailored for Corporations,
          Architects, Engineers and Owners looking to accelerate projects
          remotely and at scale.
        </InfoText>

        <div className="company__filters">
          <button
            className={`company__filterButtons ${
              selectedCategory === "Leadership" ? "active" : ""
            }`}
            onClick={() => handleCategoryChange("Leadership")}
          >
            Leadership
          </button>
          <button
            className={`company__filterButtons ${
              selectedCategory === "Reality Capture" ? "active" : ""
            }`}
            onClick={() => handleCategoryChange("Reality Capture")}
          >
            Reality Capture
          </button>
          <button
            className={`company__filterButtons ${
              selectedCategory === "BIM Delivery" ? "active" : ""
            }`}
            onClick={() => handleCategoryChange("BIM Delivery")}
          >
            BIM Delivery
          </button>
        </div>
        <Leadership category={selectedCategory} />
      </main>
    </SiteWrapper>
  );
}

const InfoText = styled("div")`
  font-size: 1.5rem;
  font-family: Helvetica;
  letter-spacing: 1px;
  text-align: center;
  line-height: 22px;
  color: #f6f6f6;
  width: 100%;
  padding: 20px;
`;

const HighlightedText = styled("div")`
  font-size: 1.5rem;
  font-family: Helvetica;
  letter-spacing: 1px;
  text-align: center;
  line-height: 20px;
  padding: 10px 20px;
  color: #ffb310;
`;

const Header = styled("div")`
  font-size: 6rem;
  font-family: Helvetica;
  letter-spacing: 1px;
  text-align: center;
  line-height: 22px;
  color: #f6f6f6;
  width: 100%;
  padding: 20px;
`;
