import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

interface LeadershipProps {
  category: string;
}

export default function Leadership({ category }: LeadershipProps) {
  const filteredTeam =
    category === "Leadership"
      ? team
      : team.filter((foo) => foo.category.includes(category));
  return (
    <div className="team-container">
      {filteredTeam.map((foo, i) => (
        <div className="team-member" key={i}>
          <div className="team-photo-wrapper">
            <img
              className="team-photo"
              src={foo.photoUrl}
              alt={foo.photoUrl}
              loading="lazy"
            />
            <div className="team-blurb-overlay">
              <p className="team-blurb-text">{foo.blurb}</p>
            </div>

            {/* {foo.url && (
              <a
                href={foo.url}
                target="_blank"
                rel="noreferrer"
                className="team-link-overlay"
              >
                <LinkedInIcon className="team-link-overlay-icon" />
              </a>
            )} */}
          </div>

          <span className="team-text team-name">{foo.name}</span>

          <div className="team-text">{foo.title}</div>
          <div className="team-text">{foo.title2}</div>
          <div className="team-links-and-certificates">
            {/* {foo.url ? (
              <a
                href={foo.url}
                target="_blank"
                rel="noreferrer"
                className="team-link-wrapper"
              >
                {/* LinkedIn */}
            {/* <LinkedInIcon className="team-link-button" /> */}
            {/* </a> */}
            {/* ) : undefined} */}
            {foo.droneCertified && (
              <div>
                <img
                  className="team-drone-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2FFAA_badge.png?alt=media&token=c9d38d00-c48a-400d-8ff6-e86a99f0ad66"
                  alt="Drone pilot certification"
                  loading="lazy"
                />
              </div>
            )}
            {foo.oshaCertified && (
              <div>
                <img
                  className="team-osha-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2Fosha.png?alt=media&token=549236f6-0173-49fb-89a0-7a1becdbb57e"
                  alt="OSHA safety certification"
                  loading="lazy"
                />
              </div>
            )}
            {foo.leedCertified && (
              <div>
                <img
                  className="team-leed-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2Fleed.png?alt=media&token=84f3013a-5041-4335-aedb-bac716e6a08f"
                  alt="LEED Green Associate Certification"
                  loading="lazy"
                />
              </div>
            )}
            {foo.revitCertified && (
              <div>
                <img
                  className="team-revit-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2Fautodesk-revit-product-icon-128%402x.png?alt=media&token=28e78114-4ac4-4720-bd67-5a83c58851c8"
                  alt="Revit Certification"
                  loading="lazy"
                />
              </div>
            )}
            {foo.autocadCertified && (
              <div>
                <img
                  className="team-autocad-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2Fautocad.png?alt=media&token=04634b7e-4f1e-46fe-bbec-032a2f691e5a"
                  alt="AutoCAD Certification"
                  loading="lazy"
                />
              </div>
            )}
            {foo.sketchupCertified && (
              <div>
                <img
                  className="team-sketchup-certification-badge"
                  src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/misc%2Fsketchup-seeklogo.png?alt=media&token=765f3267-de37-4c18-84df-b0c6188ddefc"
                  alt="SketchUp Certification"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

const team = [
  {
    url: "https://www.linkedin.com/in/will-buzan-0717ba143/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FWill%20Buzan.png?alt=media&token=e4cd2645-03a1-4769-b300-2c6971d54ee1",
    blurb:
      "Will has an engineering degree and a deep understanding of CAD/BIM delivery at scale. Will has managed thousands of digital construction projects and is a Co-Founder at Robotic Imaging.",
    title: "Principal",
    name: "Will Buzan",
    droneCertified: true,
    revitCertified: true,
    autocadCertified: true,
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/matthew-carey-908865b2/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FMatthew%20Carey.png?alt=media&token=00e119a7-7b12-4c1d-b348-24ef94a2e83f",
    blurb:
      "Matt is CIO and a Principal with 10+ years of experience in the real estate, construction and photography industry. Matt specializes in executing complex adaptive reuse projects throughout North America. Matt is an expert with on-site data capture, registration, and project management from start to finish.",
    title: "Principal",
    name: "Matthew Carey",
    droneCertified: true,
    revitCertified: true,
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/nick-chawaga-126b5076/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FNick%20Chawaga.png?alt=media&token=51278817-cfe7-4201-a6f5-8f2382099274",
    blurb:
      "Nick has a finance background and primarily supports business operations and growth initiatives. Nick is in charge of financials, human resources, and business compliance. Nick has 7+ years of commercial and residential building documentation.",
    title: "Chief Financial Officer",
    title2: "Principal",
    name: "Nick Chawaga",
    category: ["Reality Capture", "BIM Delivery"],
  },
  {
    url: "https://www.linkedin.com/in/dentonhui/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FDenton%20Hui.png?alt=media&token=2b5c4b00-58fe-4491-b35d-af4d2ddf0203",
    blurb:
      "Denton is in charge of Software Development and built the Robotic Imaging Web Application from the ground. Denton works with all departments to automate cross-departmental communications and effectively implementing growth initiatives. Denton is always enhancing the Mobile and Web Applications.",
    title: "Principal",
    name: "Denton Hui",
    category: "BIM Delivery",
  },

  {
    url: "https://www.linkedin.com/in/austin-ayres-990197180/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FAYRES-PROFESSIONAL%20HEADSHOT.png?alt=media&token=9f23bba3-030e-45d8-940a-973007097472",
    blurb:
      "Austin is the Chief Design Officer and BIM leader with 7+ experience working in Revit on a variety of complex projects. Austin is in charge of BIM execution and is a master of 3D point cloud analysis. Austin's experience is vast across Industrial, Commercial and Residential adaptive reuse projects in every state.",
    title: "Chief Design Officer, Principal",
    name: "Austin Ayres",
    leedCertified: true,
    revitCertified: true,
    autocadCertified: true,
    sketchupCertified: true,

    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/brian-downey-392503195/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FBrian%20Downey.jfif?alt=media&token=1bd5b8d9-1e61-4ce1-8e89-00e7bbaf6868",
    blurb:
      "Brian co-manages the BIM Team and has 8+ years of experience in the Architecture and Construction industry. Brian is currently pursuing his Architecture license with all required hours completed. Brian is in charge of managing the BIM modeling staff and innovation.",
    title: "Chief BIM Officer, Principal",
    name: "Brian Downey",
    revitCertified: true,
    autocadCertified: true,
    sketchupCertified: true,
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/mikechawaga/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FMike%20Chawaga.png?alt=media&token=d58f4065-ef36-432c-9b51-7cfc599a35ac",
    blurb:
      "Mike has 15+ years of startup experience and founded the Company in 2017. Mike manages growth, operations, software and client relationships.",
    title: "Principal",
    name: "Mike Chawaga",
    droneCertified: true,
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/hanadi-alnajjar-b1245a117/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FHanadi%20copy.jpg?alt=media&token=905be52f-71db-4b8c-b0c5-6819572df658",
    blurb:
      "Hanadi is a Senior BIM Manager with 20+ years of experience in architectural and interior design with a specialty in 3D visualization. Hanadi’s main responsibility is point cloud modeling in Revit and quality assurance of BIM deliverables.",
    title: "Senior BIM Manager",
    name: "Hanadi Alnajjar",
    revitCertified: true,
    autocadCertified: true,
    sketchupCertified: true,
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/joseph-chawaga-907b6b7b/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FJoe%20Chawaga.jpg?alt=media&token=d7142cc8-a9c2-4aac-a6b4-d6ea3e6acfab&_gl=1*1i390yu*_ga*MjM4OTYzNDY0LjE2OTQxMDMwOTE.*_ga_CW55HF8NVT*MTY5ODk0OTEyMS4xMjYuMS4xNjk4OTUwOTYwLjU4LjAuMA..",
    blurb:
      "Joe has a finance background and primarily manages the sales team. He is responsible for maximizing outreach and ensures initial client interactions are successful. Joe has 5+ years of commercial building documentation.",
    title: "Chief Revenue Officer",
    title2: "Principal",
    name: "Joseph Chawaga",
    droneCertified: true,
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/anya-westby-b0b55a189/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FAnya-Westby-New.jpg?alt=media&token=7994ed23-1702-4442-9158-742d88b88519",
    blurb:
      "Anya is an entrepreneur and software engineer, pioneering the vision for the Robotic Imaging Mobile App. Anya supports growth initiatives across the software, HR and marketing departments.",
    title: "VP of Software Engineering",
    name: "Anya Westby",
    category: "BIM Delivery",
  },

  {
    url: "https://www.linkedin.com/in/mike-hepp-3865b01b8/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FMike%20Hepp.png?alt=media&token=0bd9f2a6-58cf-406a-8454-c2bd654c87a9",
    blurb:
      "Mike has 4+ years of sales experience and facilitates client relationships and project execution at Robotic Imaging. Mike has a deep understanding of the business operations and deploying multi-site capture for the largest retailers in North America.",
    title: "Senior Account Manager",
    name: "Mike Hepp",
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/daniel-berry-33a1811a7/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FDaniel%20Berry.png?alt=media&token=b8aa1615-2d9f-4883-9c83-9611030fe18c",
    blurb:
      "Daniel has 4+ years of experience and facilitates client deliverables, standards and proposals. Daniel has a deep understanding of the business operations and managing projects from start to finish. Dan's industry experience permeates commercial, industrial, residential for unique adaptive reuse projects.",
    title: "Safety Director,",
    title2: "Senior Account Manager",
    name: "Daniel Berry",
    oshaCertified: true,
    category: "Reality Capture",
  },

  {
    url: "https://www.linkedin.com/in/westonfahey/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FWeston%20Fahey.png?alt=media&token=dd9e3ad7-6be4-48b7-949e-ea599f770052",
    blurb:
      "Weston has 10+ years of experience in photography and drone imaging. Wes manages scanning and registration operations throughout the US and is an expert at deploying top-notch imaging systems. Weston is responsible for project coordination, reality capture and point cloud production at scale.",
    title: "VP of Field Operations",
    name: "Weston Fahey",
    droneCertified: true,
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/sadie-buzan-237078204",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FSadie-Buzan.jpg?alt=media&token=44f839ca-d3bb-4baa-9c3f-920d8c74f32b",
    blurb:
      "Sadie standardizes client deliverables, including photo documentation and data organization. Sadie is experienced with managing proposals, client capture requests, LiDAR registration and on-site laser scanning.",
    title: "Program Coordinator",
    name: "Sadie Buzan",
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/waleedhayat/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2Fwaleed_hayat.png?alt=media&token=47108495-ac9f-4357-a641-83958890a574",
    blurb:
      "Waleed comes from a large Engineering firm and has 10+ years experience in VDC. Waleed is a driven entrepreneur, navigating growth through the early days at Robotic Imaging. Waleed is constantly improving business systems and MEP workflows.",
    title: "Senior BIM Manager",
    name: "Waleed Hayat",
    revitCertified: true,
    autocadCertified: true,
    sketchupCertified: true,
    category: "BIM Delivery",
  },

  {
    url: "https://www.linkedin.com/in/vik77/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FVictor%20Cerneleanu.jfif?alt=media&token=c9205030-a6af-463e-95d9-135f97b26c65",
    blurb:
      "Victor has 10+ years working in the construction documentation space with an expert understanding in Revit.  Victor is always advancing his knowledge and skills in complex architectural and structural modeling. Victor’s main responsibility is point cloud modeling in Revit for the most challenging projects.",
    title: "BIM Director",
    name: "Victor Cerneleanu",
    revitCertified: true,
    autocadCertified: true,
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/carlos-lemos-architecture/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FCarlos%20Lemos.jpg?alt=media&token=d0afad00-6e1c-40cd-bde8-343af19be0b3",
    blurb:
      "Carlos is a Temple Architecture Grad with skills in several design softwares as well as a thorough understanding of general architectural practices. Carlos is responsible for mobile app innovation, point cloud modeling and quality assurance of BIM/CAD deliverables. Carlos is a driven business leader with experience in new client relations, lean BIM production and innovative web and mobile applications.",
    title: "Director of Design",
    name: "Carlos Lemos",
    revitCertified: true,
    autocadCertified: true,
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/davechawaga/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2Fdave_chawaga.jpg?alt=media&token=cd41c89d-2209-42d9-afba-ae42475fa58f",
    title: "Chief People Officer",
    name: "Dave Chawaga",
    droneCertified: true,
    category: "",
  },
  {
    url: "https://www.linkedin.com/in/natalieperri/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2Fnatalie_perri.jpg?alt=media&token=0c1c0118-dd65-4e9c-b95c-174073052816",
    blurb:
      "Natalie is a master with architecture, marketing, and business leadership. Natalie has 5+ years of experience with BIM production across every real estate vertical. Natalie is a UPENN Architecture Grad with skills in several design softwares and is currently pursuing licensure. Natalie is responsible for marketing innovation, growth and client standards.",
    title: "BIM Director",
    name: "Natalie Perri",
    revitCertified: true,
    autocadCertified: true,
    category: ["Reality Capture", "BIM Delivery"],
  },
  {
    url: "https://www.linkedin.com/in/james-weaver-28a512288/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FJamie%20Weaver.jpg?alt=media&token=73af1e16-483e-4bad-9aef-ec61bf62da05&_gl=1*1euqz50*_ga*MjM4OTYzNDY0LjE2OTQxMDMwOTE.*_ga_CW55HF8NVT*MTY5ODk0OTEyMS4xMjYuMS4xNjk4OTUwOTAwLjU1LjAuMA..",
    blurb:
      "Jamie is a Senior Project Manager and is go-to leader for standard survey delivery. Jamie has 5+ experience in building industry and plumbing documentation. Jamie shows up everyday and drives home one of our core values: Discipline.",
    title: "Senior Project Manager",
    name: "Jamie Weaver",
    category: "Reality Capture",
    droneCertified: true,
  },
  {
    url: "https://www.linkedin.com/in/sam-sweeney-b9b622195/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FSam-Sweeney-Extended.png?alt=media&token=94d045af-740f-4893-a75a-7569b2bc513a",
    blurb:
      "Sam is the Director of Reality Capture and is a machine at scanning delivery.  Sam works hard every day to motivate the team and is always finding the next level. Sam is experienced in reality capture programs in almost every state.",
    title: "Director of Operations",
    name: "Sam Sweeney",
    category: "Reality Capture",
    droneCertified: true,
  },
  {
    url: "https://www.linkedin.com/in/will-evers-795a3632/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FWill-Evers.png?alt=media&token=970d2aee-4a2b-4d35-8e64-29d68ee5ddf3",
    blurb:
      "Will plays a vital role in guiding our software development at Robotic Imaging. Will helps organize and automate our internal database and processes.",
    title: "Senior Software Engineer",
    name: "Will Evers",
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/philip-zimmerman-a61877153/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FPhil.png?alt=media&token=d3b871fc-105c-4b07-8797-970a0f5adc77",
    blurb:
      "With over 15 years of experience in the startup ecosystem, Phil brings a wealth of knowledge to our team. He plays a vital role in streamlining our field operations, ensuring efficiency and innovation in our projects. ",
    title: "Senior Project Manager",
    name: "Phil Zimmerman",
    category: "Reality Capture",
    droneCertified: true,
  },
  {
    url: "https://www.linkedin.com/in/mario-h-bonaldi/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FMario-Bonaldi-Headshot.jpg?alt=media&token=0a919924-79ff-4e6e-9103-cd59fd67b6e5&_gl=1*w7c6vg*_ga*MjM4OTYzNDY0LjE2OTQxMDMwOTE.*_ga_CW55HF8NVT*MTY5NzIyOTgyMS44MS4xLjE2OTcyMzI3MjkuNTAuMC4w",
    blurb:
      "Mario has 20+ years of experience, primarily focused on mobile development, with a deep understanding of backend technologies. Mario has led software teams at numerous startup businesses delivering quality Web, IOS and Android applications.",
    title: "Mobile Software Engineer",
    name: "Mario Bonaldi",
    category: "Reality Capture",
  },
  {
    url: "",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FPatti-Seigal.png?alt=media&token=df021e85-d02d-4853-9499-2a19255743ef",
    title: "Account Manager",
    name: "Patti Seigal",
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/shane-breckenridge-22a213186/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FShane%20Breckenridge.jpeg?alt=media&token=96848d10-edc9-4514-a101-010027768da6",
    blurb:
      "Shane is a West Chester University grad with expertise in construction and financial marketing. As our Project Manager, he'll spearhead our field operations, focusing on vital data collection and strategic business development. Shane's unique blend of skills promises to be a major asset to our team's success.",
    title: "Senior Project Manager",
    name: "Shane Breckenridge",
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/albertopaez10/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FAlberto-Paez.png?alt=media&token=85c44690-05f9-4632-ab4d-81875bc69731",
    title: "BIM Specialist",
    name: "Alberto Paez",
    category: "BIM Delivery",
  },
  {
    url: "https://www.linkedin.com/in/ptv/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FPat-New-Headshot.jpg?alt=media&token=75db9ad1-d830-48db-96c3-57b0b60cd23d",
    title: "IT Director",
    name: "Patrick Voyko",
    category: "BIM Delivery",
  },
  {
    url: "",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FJohn%20Gaspar.png?alt=media&token=695c5ce0-25ff-4105-bc78-52efcd8f34bb",
    title: "Senior Director",
    name: "John Gaspar",
    category: "Reality Capture",
  },
  {
    url: "",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FAayod%20Headshot.jpg?alt=media&token=e285eb33-7a8d-4dae-afd8-a8107bef8f98",
    title: "Product Engineering Internship",
    name: "Aayod Kaul",
    category: "Reality Capture",
  },
  {
    url: "https://www.linkedin.com/in/william-walters-676ba5251/",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FWill-Walters-New.jpeg?alt=media&token=4eaa0043-5931-4108-952f-eb1bbab36902",
    title: "Reality Capture Internship",
    name: "Will Walters",
    category: "Reality Capture",
  },
  {
    url: "",
    photoUrl:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2FTim-Fiedler-New.png?alt=media&token=2a39c9a7-48c5-415e-aab4-c99becd4b23f",
    title: "Reality Capture Internship",
    name: "Tim Fiedler",
    category: "Reality Capture",
  },

  // {
  //   photoUrl:
  //     "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/team-headshots%2Fgroovy.jpg?alt=media&token=d273bd49-4756-4fbd-a16e-69799495d87d",
  //   title: "Snack Director",
  //   name: "Groovy",
  // },
];
